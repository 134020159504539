<script>
import Client from "@/components/views/home/tree/Client.vue";
import {formatChannelName} from "@/common/utils";

export default {
    components: {Client},
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    computed: {
        name() {
            return formatChannelName(this.channel?.name);
        },
        uuid() {
            return this.channel?.uuid;
        },
        isPermanent() {
            return this.channel?.is_permanent === true;
        },
        isDeleted() {
            return this.channel?.deleted_at === null;
        },
    },
}
</script>

<template>
    <ul class="list-unstyled tree">
        <li>
            <template v-if="name" >
                <i class="bi bi-chat-fill"></i>
                <router-link class="ps-1 text-decoration-none text-reset" v-if="!isDeleted && isPermanent" :to="{ name: 'channels-show', params: { uuid: uuid }}">
                    {{ name }}
                </router-link>
                <span v-else class="ps-1">{{ name }}</span>
            </template>
            <Channel v-for="channel in channel.children" :key="channel.uuid" :channel="channel"/>
            <ul class="list-unstyled">
                <Client v-for="client in channel.clients" :key="client.uuid" :client="client"/>
            </ul>
        </li>
    </ul>
</template>

<style scoped lang="scss">
.tree ul li {
  padding-left: 20px;
}
</style>