import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/views/Home.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    meta: {
      breadcrumbs: [
        { icon: 'bi-house-fill', name: 'Strona główna', to: 'home' },
        { name: 'Użytkownicy' },
      ],
    },
  },
  {
    path: '/users/show/:uuid',
    name: 'users-show',
    component: () => import(/* webpackChunkName: "about" */ '../views/UsersShow.vue'),
    meta: {
      breadcrumbs: [
        { icon: 'bi-house-fill', name: 'Strona główna', to: 'home' },
        { name: 'Użytkownicy', to: 'users' },
        { name: 'Szczególy' },
      ],
    },
  },
  {
    path: '/channels/show/:uuid',
    name: 'channels-show',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChannelShow.vue'),
    meta: {
      breadcrumbs: [
        { icon: 'bi-house-fill', name: 'Strona główna', to: 'home' },
        { name: 'Szczególy' },
      ],
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "about" */ '../views/Statistics.vue'),
    meta: {
      breadcrumbs: [
        { icon: 'bi-house-fill', name: 'Strona główna', to: 'home' },
        { name: 'Statystyki' },
      ],
    },
  },
  {
    path: '/donation',
    name: 'donation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Donation.vue'),
    meta: {
      breadcrumbs: [
        { icon: 'bi-house-fill', name: 'Strona główna', to: 'home' },
        { name: 'Wsparcie' },
      ],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
