import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVueNext from 'bootstrap-vue-next'
import VueGoogleCharts from 'vue-google-charts';

import './registerServiceWorker'
import './assets/scss/style.scss'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import dayjs from "dayjs";
import countries  from "i18n-iso-countries";

dayjs.extend(require('dayjs/plugin/relativeTime'))
require('dayjs/locale/pl');
dayjs.locale('pl');

countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

createApp(App)
    .use(store)
    .use(router)
    .use(BootstrapVueNext)
    .use(VueGoogleCharts)
    .mount('#app')
