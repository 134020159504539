<script>
import {useHomeGeneralStore} from "@/store/home/general";
import {readableDuration} from '@/common/utils';
import prettyBytes from 'pretty-bytes';

export default {
    setup() {
        const store = useHomeGeneralStore();
        return {store}
    },
    methods: {
        randomPercentage() {
            return Math.floor(Math.random() * 50) + 30 + '%';
        },
    },
    computed: {
        isLoading: function () {
            return this.store.isLoading;
        },
        uptime: function () {
            return readableDuration(this.store.data?.server?.uptime);
        },

        uptimeLong: function () {
            const value = this.store.data?.server?.uptime;
            return value === undefined ? '' : readableDuration(value, 5);
        },
        slots: function () {
            const maxClients = this.store.data?.server?.max_clients;
            if (maxClients === undefined) {
                return null;
            }

            return `${this.store.data?.server?.clients_online} / ${this.store.data?.server?.max_clients}`;
        },
        reservedSlots: function () {
            return this.store.data?.server?.reserved_slots;
        },
        clientConnections: function () {
            return this.store.data?.server?.client_connections;
        },
        ping: function () {
            const ping = this.store.data?.server?.ping;
            return ping === undefined ? null : `${parseFloat(String(ping)).toFixed(2)} ms`;
        },
        bytesReceived: function () {
            const value = this.store.data?.server?.bytes_received;
            return value === undefined ? null : prettyBytes(Number(value));
        },
        bytesSent: function () {
            const value = this.store.data?.server?.bytes_sent;
            return value === undefined ? null : prettyBytes(Number(value));
        },
        packetsReceived: function () {
            const value = this.store.data?.server?.packets_received;
            return value === undefined ? null : new Intl.NumberFormat('pl-PL').format(Number(value));
        },
        packetsSent: function () {
            const value = this.store.data?.server?.packets_sent;
            return value === undefined ? null : new Intl.NumberFormat('pl-PL').format(Number(value));
        },
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">Podstawowe informacje</div>

        <table class="table table-striped table-sm mb-0 shadow-none">
            <tbody>
            <tr>
                <td class="">Adres serwera</td>
                <td class="text-end"><a href="ts3server://lolen.pl/" class="text-decoration-none">lolen.pl</a>
                </td>
            </tr>
            <tr>
                <td>Uptime</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <span v-else v-b-tooltip.hover.left="uptimeLong">{{ uptime }}</span>
                </td>
            </tr>
            <tr>
                <td>Ilość slotów</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else-if="slots !== null">{{ slots }}<sup>({{ reservedSlots }})</sup></template>
                </td>
            </tr>
            <tr>
                <td>Ilość połączeń</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else>{{ clientConnections }}</template>
                </td>
            </tr>
            <tr>
                <td>Średni ping</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else>{{ ping }}</template>
                </td>
            </tr>
            <tr>
                <td>Wysłanych danych</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else>{{ bytesSent }}</template>
                </td>
            </tr>
            <tr>
                <td>Pobranych danych</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else>{{ bytesReceived }}</template>
                </td>
            </tr>
            <tr>
                <td>Wysłanych pakietów</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else>{{ packetsSent }}</template>
                </td>
            </tr>
            <tr>
                <td>Pobranych pakietów</td>
                <td class="text-end">
                    <BPlaceholder v-if="isLoading" :width="randomPercentage()" animation="glow"/>
                    <template v-else>{{ packetsReceived }}</template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>