// @ts-check
import { defineStore } from 'pinia'
import api from "@/common/api";

export const useHomeGeneralStore = defineStore({
    id: 'homeGeneral',
    state: () => ({
        data: null,
        isLoading: false,
        isError: false,
    }),
    getters: {
        tree: function () {
            const clientsPerChannel = {};

            const clients = (this.data?.clients ?? []).sort((a, b) => {
                if (a.order > b.order) {
                    return -1;
                }
                if (a.order < b.order) {
                    return 1;
                }
                if (a.name > b.name) {
                    return -1;
                }
                if (a.name < b.name) {
                    return 1;
                }

                return 0;
            });

            clients.forEach((client) => {
                if (!clientsPerChannel[client.channel_uuid]) {
                    clientsPerChannel[client.channel_uuid] = [];
                }
                clientsPerChannel[client.channel_uuid].push(client);
            });
            const nodes = (this.data?.channels ?? []).sort((a, b) => Number(a.order) - Number(b.order))
                .map(function (channel) {
                    return {...channel, clients: clientsPerChannel[channel.uuid] ?? []};
                });

            const helper = nodes.reduce((h, o) => (h[o.uuid] = Object.assign({}, o), h), Object.create({}));
            const tree = nodes.reduce((t, node)=> {
                const current = helper[node.uuid];

                if (current.parent_uuid === null) {
                    t.push(current);
                } else {
                    helper[node.parent_uuid].children || (helper[node.parent_uuid].children = [])
                    helper[node.parent_uuid].children.push(current);
                }

                return t;
            }, []);

            return tree;
        },
    },
    actions: {
        async fetch(inBackground = false) {
            if (!inBackground) {
                this.isLoading = true;
            }
            this.isError = false;
            console.log('fetching')
            await api.get('/server/history')
                .then((response) => {
                    this.data = response.data ?? null;
                })
                .catch(() => {
                    this.isError = true;
                    this.data = null;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        }
    },
})