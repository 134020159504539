<script>
import {useHomeGeneralStore} from "@/store/home/general";
import Info from "@/components/views/home/Info.vue";
import Tree from "@/components/views/home/Tree.vue";
import Statistics from "@/components/views/home/Statistics.vue";
import dayjs from "dayjs";
import PullToRefresh from 'pulltorefreshjs';

export default {
    components: {Statistics, Info, Tree},
    setup() {
        const store = useHomeGeneralStore();
        if (!store.data) {
            store.fetch();
        } else {
            const current = dayjs(store.data?.updated_at ?? null);
            const minuteAgo = dayjs().subtract(1, 'minute');

            if (!current.isValid() || current.isBefore(minuteAgo)) {
                store.fetch(true)
            }
        }

        return {store}
    },
    mounted() {
        this.interval = setInterval(() => {
            const current = dayjs(this.store.data?.updated_at ?? null);
            const twoMinutesAgo = dayjs().subtract(2, 'minute');
            if (!this.store.data || !current.isValid() || current.isBefore(twoMinutesAgo)) {
                this.store.fetch(true)
            }
        }, 1000*60);
        PullToRefresh.init({
            mainElement: 'body',
            onRefresh: () => {
                this.store.fetch(true);
            },
            instructionsRefreshing: 'Odświeżanie...',
            instructionsPullToRefresh: 'Przeciągnij, aby odświeżyć',
            instructionsReleaseToRefresh: 'Puść, aby odświeżyć',
            iconRefreshing: null,
            iconArrow: null
        });
    },
    beforeUnmount() {
        clearInterval(this.interval);
        PullToRefresh.destroyAll();
    },
    methods: {
        randomPercentage() {
            return Math.floor(Math.random() * 50) + 30 + '%';
        },
    },
    data() {
        return {
            interval: null,
        }
    }
}
</script>

<template>
    <BRow gutterX="3" gutterY="3">
        <BCol lg="7">
            <Tree/>
        </BCol>
        <BCol lg="5">
            <BRow gutterY="3">
                <BCol sm="12">
                    <Info/>
                </BCol>
                <BCol sm="12">
                    <Statistics/>
                </BCol>
            </BRow>
        </BCol>
    </BRow>
</template>

<style scoped>

</style>