<script>
import {useHomeGeneralStore} from "@/store/home/general";
import Channel from "@/components/views/home/tree/Channel.vue";
import dayjs from "dayjs";
export default {
    components: {Channel},
    setup() {
        const general = useHomeGeneralStore()
        return {general};
    },
    mounted() {
        this.interval = setInterval(this.updateNow, 1000);
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    data() {
        return {
            interval: null,
            now: dayjs(),
        }
    },
    methods: {
        updateNow() {
            this.now = dayjs();
        },
        fetch() {
            this.general.fetch();
        },
    },
    computed: {
        isError() {
            return this.general?.isError;
        },
        isLoading() {
            return this.general?.isLoading;
        },
        lastUpdatedHuman() {
            const updatedAt = this.general?.data?.updated_at;
            if (!updatedAt) {
                return null;
            }

            if (dayjs(updatedAt).isAfter(this.now.clone().subtract(10, 'seconds'))) {
                return 'aktualne';
            }

            return dayjs(updatedAt).from(this.now);
        },
        lastUpdated() {
            const updatedAt = this.general?.data?.updated_at;
            if (!updatedAt) {
                return null;
            }

            return dayjs(updatedAt).format('HH:mm:: DD.MM.YYYY');
        },
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            Aktualnie na serwerze
            <div class="float-end">
                <BSpinner v-if="isLoading" small/>
                <span v-else-if="lastUpdatedHuman" class="small">
                    <span class="d-none d-sm-inline-block">Ostatnia aktualizacja:</span>
                    <span v-b-tooltip.hover.left="lastUpdated">{{lastUpdatedHuman}}</span>
                </span>
            </div>
        </div>
        <div class="card-body">
            <div v-if="isError" class="alert alert-danger" role="alert">
                Wystąpił błąd podczas pobierania danych. Kliknij <a @click="fetch" class="alert-link">tutaj</a> aby spróbować ponownie.
            </div>
            <div v-if="isLoading" class="alert alert-info loading" role="alert">Trwa ładowanie danych</div>
            <ul class="list-unstyled mb-0" v-else>
                <Channel v-for="channel in general.tree" :key="channel.uuid" :channel="channel"/>
            </ul>
        </div>
    </div>
</template>

<style scoped lang="scss">
.small {
    font-size: .7em;
}
</style>