<script>
import {useHomeStatisticsStore} from "@/store/home/statistics";
import {GChart} from "vue-google-charts";
import dayjs from "dayjs";
import {dayjsRange} from "@/common/utils";

export default {
    components: {GChart},
    setup() {
        const store = useHomeStatisticsStore();
        if (!store.data) {
            store.fetch();
        } else {
            const current = dayjs(store.downloadedAt);
            const fifteenMinutesAgo = dayjs().subtract(15, 'minute');

            if (!current.isValid() || current.isBefore(fifteenMinutesAgo)) {
                store.fetch(true)
            }
        }
        return {store}
    },
    mounted() {
        this.interval = setInterval(() => this.store.fetch(true), 1000 * 60 * 15);
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    data() {
        return {
            interval: null,
        }
    },
    computed: {
        perHours: function () {
            const now = dayjs().set('m', 0).set('s', 0).set('ms', 0);
            const dayAgo = now.subtract(23, 'hour');
            const fetched = {};

            [...this.store.perHours].forEach((value) => {
                const key = dayjs(value?.date).format('HH:mm DD.MM.YYYY');
                fetched[key] = value?.quantity_max ?? 0;
            });

            return [
                ['Data', 'Ilość osób', {role: 'annotation'}],
                ...dayjsRange(dayAgo, now, 'hour').map((value) => {
                    const key = value.format('HH:mm DD.MM.YYYY');
                    const quantity = fetched[key] ?? 0;
                    return [key, quantity, quantity <= 0 ? null : quantity];
                })
            ];
        },

        perDates: function () {
            const now = dayjs().set('m', 0).set('s', 0).set('ms', 0);
            const weekAgo = now.subtract(6, 'day');
            const fetched = {};

            [...this.store.perDates].forEach((value) => {
                const key = dayjs(value?.date).format('DD.MM.YYYY');
                fetched[key] = value?.quantity_max ?? 0;
            });

            return [
                ['Data', 'Ilość osób', {role: 'annotation'}],
                ...dayjsRange(weekAgo, now, 'day').map((value) => {
                    const key = value.format('DD.MM.YYYY');
                    const quantity = fetched[key] ?? 0;
                    return [key, quantity, quantity <= 0 ? null : quantity];
                })
            ];
        },
        chartOptions: function () {
            return {
                pointSize: 3,
                vAxis: {
                    textPosition: 'none',
                    viewWindow: {
                        max: this.store.maxClients,
                        min: 0,
                    },
                },
                hAxis: {
                    textPosition: 'none',
                },
                legend: 'none',
                backgroundColor: 'transparent',
                chartArea: {width: '100%', height: '100%'},
            }
        }

    },
}
</script>

<template>
    <div class="card">
        <div class="card-header">Ostatnie 24 godziny</div>
        <GChart v-if="store.isCorrect && !store.isLoading"
                class="chart-line"
                type="LineChart"
                :data="perHours"
                :options="chartOptions"
        />
        <div v-else-if="store.isLoading" class="card-body text-center p-4">
            <BSpinner />
        </div>
    </div>
    <div class="mb-3"></div>
    <div class="card">
        <div class="card-header">Ostatnie 7 dni</div>
        <GChart v-if="store.isCorrect && !store.isLoading"
                class="chart-line"
                type="LineChart"
                :data="perDates"
                :options="chartOptions"
        />
        <div v-else-if="store.isLoading" class="card-body text-center p-4">
                <BSpinner />
        </div>
    </div>
</template>