<script>
import countries from 'i18n-iso-countries';

export default {
    props: {
        client: {
            type: Object,
            required: true,
        },
    },
    computed: {
        name() {
            return this.client?.name ?? null;
        },
        uuid() {
            return this.client?.uuid ?? null;
        },
        deletedAt() {
            return this.client?.deleted_at ?? null;
        },
        isAway() {
            return this.client?.is_away ?? false;
        },
        isInputMuted() {
            return this.client?.is_input_muted ?? false;
        },
        isOutputMuted() {
            return this.client?.is_output_muted ?? false;
        },
        country() {
            const country = this.client?.country ?? null;

            if(!country){
                return null;
            }

            return String(country).toLowerCase();
        },
        countryName() {
            return countries.getName(String(this.client?.country).toLowerCase() ?? null, 'pl');
        },
    }
};
</script>

<template>
    <li>
        <i class="bi bi-person-fill"></i>
        <router-link class="text-decoration-none text-reset" v-if="deletedAt === null" :to="{ name: 'users-show', params: { uuid }}">
            {{ name }}
        </router-link>
        <template v-else>
            {{ name }}
        </template>
        <div class="float-end">
            <i v-if="isInputMuted" class="bi bi-mic-mute" v-b-tooltip.hover.top="'Wyciszony mikrofon'"></i>
            <i v-if="isOutputMuted" class="bi bi-volume-mute" v-b-tooltip.hover.top="'Wyciszony dzwięk'"></i>
            <i v-if="isAway" class="bi bi-clock-history" v-b-tooltip.hover.top="'Zaraz wracam'"></i>
            <span v-if="country" class="flag" :class="`flag-${country}`" v-b-tooltip.hover.top="countryName"/>
        </div>
    </li>
</template>

<style scoped lang="scss">
.float-end span {
  margin-left: 3px;
}
</style>