import humanizeDuration from 'humanize-duration';
export const readableDuration = (value, largest = 3) => !Number.isInteger(value) ? null : humanizeDuration(value * 1000, {
    language: 'pl',
    largest: largest,
    units: ['y', 'mo', 'w', 'd', 'h', 'm'],
    round: true,
});

export function dayjsRange(start, end, unit) {
    const range = [];
    let current = start;
    while (!current.isAfter(end, unit)) {
        range.push(current);
        current = current.add(1, unit);
    }
    return range;
}

export function formatChannelName(name) {
    return String(name).replace(/\[[c]?spacer[0-9]*\]/gi, '') ?? null;
}

export function throttle(mainFunction, delay) {
    let timerFlag = null;

    return (...args) => {
        if (timerFlag === null) {
            mainFunction(...args);
            timerFlag = setTimeout(() => {
                timerFlag = null;
                mainFunction(...args);
            }, delay);
        }
    };
}

export default {readableDuration, throttle};
