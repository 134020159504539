<template>
    <Navbar/>
    <BContainer class="pb-5">
        <BBreadcrumb v-if="showBreadcrumbs">
            <BBreadcrumbItem v-for="breadcrumb in breadcrumbs" :key="breadcrumb.name" :active="!breadcrumb.to"
                             :to="{name: breadcrumb.to}">
                <i v-if="breadcrumb.icon" class="bi pe-1" :class="breadcrumb.icon"></i>
                <template v-if="breadcrumb.name">{{ breadcrumb.name }}</template>
            </BBreadcrumbItem>
        </BBreadcrumb>

        <router-view/>
    </BContainer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Pusher from "pusher-js";
import {useHomeGeneralStore} from "@/store/home/general";
import {throttle} from "@/common/utils";
Pusher.logToConsole = true;

export default {
    name: 'App',
    components: {Navbar},
    setup() {
        const store = useHomeGeneralStore();
        return {store}
    },
    mounted() {
        this.breadcrumbs = this.$route.meta.breadcrumbs;

        if(process.env.VUE_APP_PUSHER_KEY && process.env.VUE_APP_PUSHER_CLUSTER) {
            this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER
            });

            const channel = this.pusher.subscribe('statistics');
            const fetch = throttle(() => this.store.fetch(true), 10000);
            channel.bind('update', () => fetch());
        }

    },
    data() {
        return {
            pusher: null,
            breadcrumbs: [],
        };
    },
    computed: {
        showBreadcrumbs() {
            if (!Array.isArray(this.breadcrumbs)) {
                return false;
            }

            return this.breadcrumbs.length > 1;
        },
    },
    watch: {
        $route(value) {
            this.breadcrumbs = value.meta.breadcrumbs;
        },
    },
};
</script>

<style lang="scss">
.navbar-brand img {
  height: 25px;
}

.navbar-brand sup {
  font-size: .5em;
}
.breadcrumb-item a {
    text-decoration: none;
}
</style>
