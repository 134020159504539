<script>
export default {
    data() {
        return {
            theme: "light",
        };
    },
    mounted() {
        let theme = "light";
        if("theme" in localStorage){
            theme = localStorage.getItem("theme");
        } else {
            theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        }
        this.setTheme(theme === "dark" ? "dark" : "light")
    },
    methods: {
        setTheme(variant) {
            this.theme = variant;
            localStorage.setItem("theme", this.theme);
            document.documentElement.dataset.bsTheme = this.theme;
        },
        toggleTheme() {
            if (this.theme === "dark") {
                this.setTheme("light");
            } else {
                this.setTheme("dark");
            }
        },
    },
}
</script>

<template>
    <BNavbar toggleable="lg" class="bg-body-tertiary mb-4" :container="true" sticky="top">
        <BNavbarBrand to="/">
            <img src="../assets/logo.png" class="d-inline-block align-top" alt="lolen">
            lolen <sup>ts</sup>
        </BNavbarBrand>
        <BNavbarToggle target="nav-collapse"/>
        <BCollapse id="nav-collapse" is-nav>
            <BNavbarNav class="me-auto mb-2 mb-lg-0">
                <BNavItem to="/">Strona główna</BNavItem>
                <BNavItem to="/statistics">Statystyki</BNavItem>
                <BNavItem to="/donation">Wsparcie</BNavItem>
            </BNavbarNav>
            <BNavbarNav class="d-flex">
                <BNavItem @click="toggleTheme">
                    <i :class="theme === 'dark' ? 'bi bi-brightness-high' : 'bi bi-moon'"></i>
                </BNavItem>
            </BNavbarNav>
        </BCollapse>
    </BNavbar>
</template>