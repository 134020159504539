// @ts-check
import { defineStore } from 'pinia'
import api from "@/common/api";
import dayjs from "dayjs";

export const useHomeStatisticsStore = defineStore({
    id: 'homeStatistics',
    state: () => ({
        data: null,
        isLoading: true,
        isLoadingInBackground: false,
        isError: false,
        downloadedAt: null,
    }),
    getters: {
        perDates: function () {
            return this.data?.date ?? [];
        },
        perHours: function () {
            return this.data?.hour ?? [];
        },
        isCorrect: function () {
            return Array.isArray(this.data?.date) && Array.isArray(this.data?.hour);
        },
        maxClients: function () {
            const values = [
                ...(this.data?.date ?? []).map(value => value?.quantity_max ?? 0),
                ...(this.data?.hour ?? []).map(value => value?.quantity_max ?? 0)
            ];
            const roundTo = 5;
            const value = values.length > 0 ? Math.max(...values) : 0;
            return Math.round((value + roundTo + 1) / roundTo) * roundTo;
        }
    },
    actions: {
        async fetch(inBackground = false) {
            if (!inBackground) {
                this.isLoading = true;
            }
            this.isError = false;
            this.downloadedAt = null;
            await api.get('/server/statistics')
                .then((response) => {
                    this.data = response.data ?? null;
                    this.downloadedAt = dayjs();
                })
                .catch(() => {
                    this.isError = true;
                    this.data = null;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        }
    },
})